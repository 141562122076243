import React, { useEffect, useState } from 'react'
import { convertTime, fetchData, handleRefresh } from './utils'
import styles from './etradeDetails.module.css'
import { Pagination } from 'antd'
import { Spinner } from 'shared-components/'
import { ReloadOutlined } from '@ant-design/icons'

const EtradeDetails = () => {
  const [etradeData, setEtradeData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [searchText, setSearchText] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const pageSize = 10

  const handlePageChange = page => {
    setCurrentPage(page)
  }

  const fetchEtradeData = async () => {
    try {
      await fetchData({
        setIsLoading,
        setEtradeData,
        setTotalPages,
        currentPage,
        email: searchText
      })
    } catch (error) {
      console.log('Error while fetching etrade details:', error)
    }
  }

  useEffect(() => {
    fetchEtradeData()
  }, [currentPage])

  return (
    <div className={styles.container}>
      <Spinner isFetching={isLoading} />
      <div className={styles.searchUser}>
        <input
          className={styles.search}
          placeholder="Search By Email ID"
          type="text"
          value={searchText}
          onChange={event => setSearchText(event.target.value)}
        />
        <button onClick={fetchEtradeData} className={styles.searchBtn}>
          Search
        </button>
      </div>
      <table>
        <thead>
          <tr>
            <th>User ID</th>
            <th>Email ID</th>
            <th>Account ID</th>
            <th>Order ID</th>
            <th>Created At</th>
            <th>Status</th>
            <th>Ticker</th>
            <th>Order Type</th>
            <th>Quantity</th>
            <th>Order Price</th>
            <th>Order Executed Price</th>
            <th>Order Executed Status</th>
          </tr>
        </thead>
        <tbody>
          {etradeData.length > 0
            ? etradeData.map(order => (
                <tr key={order.id}>
                  <td>{order.user_id}</td>
                  <td>{order.email_id || 'N/A'}</td>
                  <td>{order.account_id}</td>
                  <td>{order.order_id}</td>
                  <td>{convertTime(order.created_at)}</td>
                  <td>{order.status}</td>
                  <td>{order.ticker}</td>
                  <td>{order.order_type}</td>
                  <td>{order.quantity}</td>
                  <td>{order.order_price}</td>
                  <td>
                    {order.order_executed_price || '-'}
                    {order.status.toLowerCase() === 'success' ? (
                      <ReloadOutlined
                        style={{
                          marginLeft: '10px',
                          cursor: 'pointer',
                          color: 'red'
                        }}
                        onClick={() => {
                          handleRefresh({
                            accountId: order.account_id,
                            orderId: order.order_id,
                            setIsLoading
                          })
                        }}
                      />
                    ) : null}
                  </td>
                  <td>{order.order_executed_status || '-'}</td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={totalPages}
        onChange={handlePageChange}
        showSizeChanger={false}
      />
    </div>
  )
}

export default EtradeDetails
