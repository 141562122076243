import axios from 'axios'
import config from '../../../env'
import { getNotification } from '../utils'

export const fetchData = async ({
  setIsLoading,
  setEtradeData,
  setTotalPages,
  currentPage,
  email
}) => {
  try {
    setIsLoading(true)
    const encodedEmail = encodeURIComponent(email || '')
    const url = `${config.tradeServiceBaseUrl}/v1/admin/user/etrade_orders?user_email=${encodedEmail}&page=${currentPage}`
    const response = await axios.get(url, {
      headers: {
        Authorization: `${localStorage.getItem('token')}`
      }
    })

    if (response.data) {
      setIsLoading(false)
      setEtradeData(response.data.message || [])
      setTotalPages(response.data.pages || 0)
    }
  } catch (error) {
    setIsLoading(false)
    getNotification('Error', 'User not found')
    console.error('Error while fetching etrade details:', error)
    throw new Error('Failed to fetch data')
  } finally {
    setIsLoading(false)
  }
}

export const handleRefresh = async ({ accountId, orderId, setIsLoading }) => {
  try {
    setIsLoading(true)
    const url = `${config.tradeServiceBaseUrl}/v1/admin/user/${accountId}/etrade_order/${orderId}`
    const response = await axios.get(url, {
      headers: {
        Authorization: `${localStorage.getItem('token')}`
      }
    })

    if (response.data) {
      setIsLoading(false)
      getNotification('success', 'Fetched order details')
      return response.data
    }
  } catch (error) {
    setIsLoading(false)
    getNotification('Error', 'Failed to fetch order details')
    console.error('Failed to fetch order details:', error)
  } finally {
    setIsLoading(false)
  }
}

export const convertTime = createdAt => {
  const utcTimeStamp = createdAt.replace(' ', 'T') + 'Z'
  const date = new Date(utcTimeStamp)
  const userLocale = navigator.language || 'en-US'

  const formattedDate = date.toLocaleString(userLocale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true
  })

  return formattedDate
}
