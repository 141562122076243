import axios from 'axios';
import config from '../../../env';
import { getNotification } from '../utils';
export const updateUserInformation = async (userData) => {
    try {
        const response = await axios({
            method: 'post',
            url: `${config.tradeServiceBaseUrl}/v1/admin/user/update_information`,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${localStorage.getItem('token')}`
            },
            data: userData,
        });
        if (response.data) {
            getNotification(
                'success',
                "User information updated successfully"

            )
        }
        return response.data;
    } catch (error) {
        getNotification(
            'error',
            "Error updating user information"
        )
        console.error('Error updating user information:', error);
        throw error;
    }
};

// Example usage:
// const userData = {
//   first_name: "test",
//   last_name: "123",
//   user_id: 8282,
//   address: "connecticute",
//   zipcode: "199180"
// };
// 
// updateUserInformation(userData)
//   .then(response => console.log(response))
//   .catch(error => console.error(error));
