import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { updateUserInformation } from './actions';

const UpdateUserWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  min-height: 100vh;
  margin: 0 auto;
  background-color: #f5f5f5;
  border-radius: 8px;
`;

const StyledForm = styled(Form)`
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  font-weight: 500;
  margin-bottom: 0.5rem;
  // gray-700
  color: #4f4f4f;
`;

const StyledInput = styled(Field)`
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  color: red;
  font-size: 0.875rem;
  margin-top: 0.25rem;
`;

const SubmitButton = styled.button`
  background-color: #1565c0;
  max-width: 20%;
  color: white;
  padding:  0.5rem 1rem;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #1565c0;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.6rem;
  
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FullWidthField = styled(FormField)`
  grid-column: 1 / -1;
`;

const RequiredFieldMessage = styled.span`
  color: #666;
  font-size: 0.75rem;
  margin-top: 0.25rem;
`;

const validationSchema = Yup.object().shape({
  user_email: Yup.string().email('Invalid email address').required('Email is required'),
  first_name: Yup.string(),
  last_name: Yup.string(),
  address: Yup.string(),
  zipcode: Yup.string().matches(/^\d{5}$/, 'Zipcode must be 5 digits'),
  phone: Yup.string().matches(/^\d{10}$/, 'Phone number must be 10 digits'),
  state: Yup.string(),
  city: Yup.string(),
});

const UpdateUser = () => {
  const initialValues = {
    first_name: '',
    last_name: '',
    user_email: '',
    address: '',
    zipcode: '',
    phone: '',
    state: '',
    city: '',
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    await updateUserInformation(values).then(() => {
      setSubmitting(false);
    }).catch((error) => {
      console.error(error);
      setSubmitting(false);
    });
  };

  return (
    <UpdateUserWrapper>
      <h2 style={{
        fontWeight: '600',
        marginBottom: '0',
      }}>Update User</h2>
      <hr style={{
        maxWidth: '800px',
        height: '1px',
        backgroundColor: '#e0e0e0',
        border: 'none',
        margin: '1rem 0',
      }} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, errors, touched, values }) => {
          const isEmailValid = values.user_email !== '' && !errors.user_email && Yup.string().email('Invalid email address').required('Email is required').isValidSync(values.user_email);
          return (
            <StyledForm>
              <FormGrid>
                <FullWidthField>
                  <StyledLabel htmlFor="user_email">Email*</StyledLabel>
                  <RequiredFieldMessage>This field is required</RequiredFieldMessage>
                  <StyledInput type="email" id="user_email" name="user_email" />
                  <StyledErrorMessage name="user_email" component="div" />
                </FullWidthField>

                {['first_name', 'last_name', 'phone', 'zipcode', 'state', 'city'].map((field) => (
                  <FormField key={field}>
                    <StyledLabel htmlFor={field}>{field.replace('_', ' ').charAt(0).toUpperCase() + field.replace('_', ' ').slice(1)}</StyledLabel>
                    <StyledInput
                      type="text"
                      id={field}
                      name={field}
                      disabled={!isEmailValid}
                    />
                    <StyledErrorMessage name={field} component="div" />
                  </FormField>
                ))}

                <FullWidthField>
                  <StyledLabel htmlFor="address">Address</StyledLabel>
                  <StyledInput
                    type="text"
                    id="address"
                    name="address"
                    disabled={!isEmailValid}
                  />
                  <StyledErrorMessage name="address" component="div" />
                </FullWidthField>
              </FormGrid>

              <SubmitButton type="submit" disabled={isSubmitting || !isEmailValid}>
                {isSubmitting ? 'Updating...' : 'Update User'}
              </SubmitButton>
            </StyledForm>
          );
        }}
      </Formik>
    </UpdateUserWrapper>
  );
};

export default UpdateUser;
