// absolute imports
import { Layout, Result } from 'antd'
import classNames from 'classnames'
import React, { Fragment } from 'react'
import { ContainerQuery } from 'react-container-query'
import DocumentTitle from 'react-document-title'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'

import { logout } from 'actions/auth'
import Authorized from 'common/Authorized'
import { PrivateRoute } from 'shared-components'
import Accounts from './Accounts'
import AccountDetails from './Accounts/Pages/AccountDetails.js'

import Alerts from './Alerts'
import Cash from './Cash'
import Cryptocurrency from './CryptocurrencyMaster'
import Sponsers from './Sponsers'
import Trades from './Trades'
import Treasury from './Treasury'

import { hideHeaderForRoutes, routerPages } from 'routermenu'
import Header from './Header'
import PopulateDetails from './PopulateDetails'
import UserRequests from './UserRequests'
import UpdateUser from './UpdateUser'
import EtradeDetails from './EtradeDetails'

const { Content } = Layout
const { AuthorizedRoute, check } = Authorized

const query = {
  'screen-xs': {
    maxWidth: 575
  },
  'screen-sm': {
    minWidth: 576,
    maxWidth: 767
  },
  'screen-md': {
    minWidth: 768,
    maxWidth: 991
  },
  'screen-lg': {
    minWidth: 992,
    maxWidth: 1199
  },
  'screen-xl': {
    minWidth: 1200
  }
}

class WebDashboardLayout extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      headerHeight: '',
      notify: false
    }
  }

  componentWillMount() { }

  componentDidMount() {
    document.body.style.paddingTop = 0
  }

  getPageTitle() {
    const { location } = this.props
    const { pathname } = location
    let title = 'Magnifi'
    if (
      routerPages[pathname] &&
      routerPages[pathname].name &&
      routerPages[pathname].title
    ) {
      title = routerPages[pathname].title //`${routerData[pathname].name} - Magnifi`;
    }
    return title
  }

  render() {
    document.body.classList.add('p-0')
    const {
      routerData,
      match,
      view,
      modalToggleState,
      loggedIn,
      loading,
      location,

      loggingIn,

      logo
    } = this.props
    const { headerHeight } = this.state
    const { pathname } = location

    const layout = (
      <Layout
        style={{
          minHeight: '100vh'
        }}
      >
        {!hideHeaderForRoutes.includes(pathname) && (
          <Header {...this.props} logo={logo} />
        )}
        <Content
          id="web-dashboard-content-wrapper"
          className="web-dashboard-content-wrapper"
          style={{ marginTop: headerHeight }}
        >
          <Fragment>
            <Switch>
              <Redirect exact path="/" to="/login" />
              <PrivateRoute path="/accounts" component={Accounts} />
              <PrivateRoute path="/account/:id" component={AccountDetails} />
              <PrivateRoute path="/trades" component={Trades} />
              <PrivateRoute path="/notifications" component={Alerts} />
              <PrivateRoute path="/sponsers" component={Sponsers} />
              <PrivateRoute path="/user-requests" component={UserRequests} />
              <PrivateRoute path="/cash" component={Cash} />
              <PrivateRoute path="/cryptocurrency" component={Cryptocurrency} />
              <PrivateRoute path="/treasury" component={Treasury} />
              <PrivateRoute path="/update-user" component={UpdateUser} />
              <PrivateRoute
                path="/populate-details"
                component={PopulateDetails}
              />
              <PrivateRoute path="/etrade-details" component={EtradeDetails} />
              <Route render={NotFound} />
            </Switch>
            {/*!loggedIn && premiumModal && <LoginWall onToggle={() => this.closePremiumModal()} history={this.props.history}/>*/}
          </Fragment>
        </Content>
      </Layout>
    )

    return (
      <DocumentTitle title={this.getPageTitle()}>
        <ContainerQuery query={query}>
          {params => <div className={classNames(params)}>{layout}</div>}
        </ContainerQuery>
      </DocumentTitle>
    )
  }
}

const mapStateToProps = ({ auth }) => ({
  loggedIn: auth.loggedIn,
  loggingIn: auth.loggingIn
})

const mapDispatchToProps = {
  logoutHandler: logout
}

export default connect(mapStateToProps, mapDispatchToProps)(WebDashboardLayout)

const NotFound = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
    />
  )
}
